@import '~antd/dist/antd.css';

* {
  /*font-family: 'PT Sans', sans-serif !important;*/
}

/* Carousel Slide */
.slider-wrapper .slider .slide {
  cursor: pointer;
}

.a-link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}